@tailwind base;
@tailwind components;
@tailwind utilities;

.separator:before {
  content: " | ";
  margin: 0 8px;
}

@layer base {
  @font-face {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/SF-Pro-Text-Regular.otf') format('opentype');
  }

  @font-face {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/SF-Pro-Text-Medium.otf') format('opentype');
  }

  @font-face {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/SF-Pro-Text-Semibold.otf') format('opentype');
  }

  @font-face {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/SF-Pro-Text-Bold.otf') format('opentype');
  }

  @font-face {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/SF-Pro-Text-Black.otf') format('opentype');
  }

  @font-face {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/SF-Pro-Text-Heavy.otf') format('opentype');
  }

  @font-face {
    font-family: 'Georgia Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/GeorgiaPro-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Georgia Pro';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/GeorgiaPro-LightItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Georgia Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/GeorgiaPro-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Georgia Pro';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/GeorgiaPro-Italic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Georgia Pro';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/GeorgiaPro-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Georgia Pro';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/GeorgiaPro-SemiBoldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Georgia Pro';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/GeorgiaPro-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Georgia Pro';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/GeorgiaPro-BoldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Georgia Pro';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/GeorgiaPro-Black.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Georgia Pro';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/GeorgiaPro-BlackItalic.ttf') format('truetype');
  }
}

@layer utilities {

  .no-scrollbar,
  .no-scrollbar * {
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .rounded-4xl {
    border-radius: 2rem;
  }

  .px-0-important {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .animated-text {
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
}

.react-select__control {
  border-radius: 1.5rem !important;
  height: 44px;

  .react-select__placeholder,
  .react-select__single-value {
    margin-left: 0 !important;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .react-select__placeholder {
    color: #758595;
  }

  .react_select__single-value {
    --tw-text-opacity: 1;
    color: rgb(63 72 81 / var(--tw-text-opacity)) !important;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__clear-indicator {
    cursor: pointer;
  }

  .react-select__input-container {
    margin-left: 0 !important;
    --tw-text-opacity: 1;
    color: rgb(63 72 81 / var(--tw-text-opacity));
  }

  .react-select__value-container,
  .react-select__placeholder-container {
    margin-left: 1rem !important;
    padding: 0;
  }

  .react-select__value-container--is-multi {
    overflow: scroll;
    max-height: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.react-select__control--is-focused {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  --tw-border-opacity: 1;
  border-color: rgb(25 133 204 / var(--tw-border-opacity)) !important;
}

.react-select__control:not(.react-select__control--is-focused) {
  --tw-border-opacity: 1;
  border-color: rgb(164 180 188 / var(--tw-border-opacity)) !important;
}

html.dark {
  .react-select__control {
    --tw-bg-opacity: 1;
    background-color: rgb(27 31 35 / var(--tw-bg-opacity));

    .react-select__single-value {
      color: #FFFFFF !important;
    }

    .react-select__multi-value {
      background-color: rgb(15 17 19 / var(--tw-bg-opacity));
      color: white;

      .react-select__multi-value__label {
        color: inherit;
      }
    }
  }

  .react-select__menu {
    background-color: rgb(15 17 19 / var(--tw-bg-opacity));

    .react-select__option {
      color: white;
    }

    .react-select__option:hover,
    .react-select__option--is-focused,
    .react-select__option--is-selected {
      background-color: rgb(27 31 35 / var(--tw-bg-opacity));
    }
  }

  .react-select__control:not(.react-select__control--is-focused) {
    --tw-border-opacity: 1;
    border-color: rgb(63 72 81 / var(--tw-border-opacity)) !important;
  }
}

input,
textarea,
button {
  outline: none;
}

mark {
  background-color: inherit;
  font-weight: 500;
}

.dark {
  mark {
    background-color: inherit;
    color: white;
  }
}


@media print {
  .hide-print {
    display: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #1B1F23 !important;
  caret-color: #1B1F23 !important;
}

/* Dark mode için otomatik doldurulan input stilleri */
.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #1B1F23 inset !important;
  -webkit-text-fill-color: white !important;
  caret-color: white !important;
}